export const ROUTE_HOME = 'ROUTE_HOME';
export const ROUTE_PHONE = 'ROUTE_PHONE';
export const ROUTE_CARD_SELECT = 'ROUTE_CARD_SELECT';
export const ROUTE_PROFILE = 'ROUTE_PROFILE';

export const ROUTE_CARD_INFO = 'ROUTE_CARD_INFO';
export const ROUTE_CARD_HISTORY = 'ROUTE_CARD_HISTORY';
export const ROUTE_CARD_TARIFICATIONS = 'ROUTE_CARD_TARIFICATIONS';
export const ROUTE_CARD_SUBSCRIPTIONS = 'ROUTE_CARD_SUBSCRIPTIONS';

export const ROUTE_CONTENT_PAGE = 'ROUTE_CONTENT_PAGE';
