<template>
  <!-- Перекрытие которое включается, если ошибка или диалог-->
  <v-overlay :value="isLoading || isError"  :opacity="0.3" class="text-center" color="#2c3c4d">

    <!-- Если ошибка показываем диалог с ошибкой -->
      <v-dialog  :value="isError" @input="onDlgChange"  overlay-color="#2c3c4d" width="300" class="rounded-xl">
      <v-card class="card_error py-10 px-6 rounded-lg">
        <div class="mx-auto width-216">
          <v-card-title class="text_button-mob-b2-semi-bold color_text-primary justify-center pa-0 mb-4">{{ errorsHeader }}</v-card-title>
          <div class="" v-for="(message, messageIndex) in errors" :key="messageIndex">
              <div v-for="(line, lineIndex) in message ? message.split('\n') : []" :key="lineIndex"  class="width-216 mx-auto error_message text_secondary-mob-st1-regular color_text-primary mb-6">
                {{ line }}
              </div>
            </div>
        </div>
        <v-card-actions class="justify-center pa-0">
          <v-btn :style="{ backgroundColor: colorButton, color: colorText }" style="width: 216px; height: 52px; border-radius: 48px;" 
          class="d-block ma-0 pa-0 text_button-mob-b2-semi-bold button__bluebckgrnd width-216 brdrds_48px height-52px" @click="onDlgChange(false)">Понятно</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Если загрузка, показываем загрузчик -->
    <v-progress-circular v-if="isLoading" indeterminate size="64"></v-progress-circular>

  </v-overlay>
</template>

<script>
import { PROGRESS_STATE_ERROR, PROGRESS_STATE_IN_PROGRESS } from '@/store/const-progress';
import { MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN, MUTATION_PROGRESS_NONE } from '@/store/const-mutations';

export default {
  name: 'gk-progress',

  props: {
    tag: { type: String, default: null }
  },

  computed: {
    isLoading: (vm) => vm.isInProgressStateAndTag(PROGRESS_STATE_IN_PROGRESS),

    isError: (vm) => vm.isInProgressStateAndTag(PROGRESS_STATE_ERROR),

    colorButton: (vm) => vm.$store.state.shared.colorButton,

    colorText: (vm) => vm.$store.state.shared.colorText,

    errorsHeader: function () {
      const errorsArray = this.$store.state.progress.error && this.$store.state.progress.error.errors;
      if (Array.isArray(errorsArray) && (errorsArray.length) > 0 && (errorsArray[0]) && (errorsArray[0].errorClass)) {
        if ((errorsArray[0].errorClass.toUpperCase() === 'WrongCodeException'.toUpperCase())) {
          return 'Неверный код';
        }
      }
      if (this.$store.state.progress.error && this.$store.state.progress.error.status === 401) {
        return 'Авторизация';
      }
      return 'Ошибка!';
    },

    errors: function () {
      if (this.isError) {
        const errors = this.$store.state.progress.error.errors;
        if (errors) {
          const result = errors.map((item) => item.message);
          return result;
        }
        return [];
      }
      return [];
    }
  },

  methods: {
    isInProgressStateAndTag: function (state) {
      if (!this.$store.state.progress)
        return false;
      if (this.$store.state.progress.state === state) {
        if (this.tag !== null && this.tag !== undefined) {
          return this.$store.state.progress.tag === this.tag;
        }
        return true;
      }
      else
        return false;
    },

    // В результате пользовательского взаимодействия изменилось состояние модели диалога об ошибке
    onDlgChange: function (value) {
      if (value === false) {
        try {
          // Если ошибка была с авторизацией, то убиваем токен авторизации (разлогиниваемся) и переходим на стартовую страницу
          if (this.isError)
            if (this.$store.state.progress.error.status === 401) {
              this.$store.commit(MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN);
              this.$router.push('/');
            }
        }
        finally {
        // Снимаем ошибку в хранилище
          this.$store.commit(MUTATION_PROGRESS_NONE);
        }
      }
    }
  }
};
</script>

<style lang="scss">

.v-dialog {
  border-radius: 24px;
}
.card_error {
  width: 300px;
}

.error_message {
  text-align: center;
}
</style>
