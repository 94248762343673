<template>
  <v-app>
    <v-main v-if="configIsLoaded">
      <router-view  />
    </v-main>
    <gk-progress tag="api"/>
  </v-app>
</template>

<script>
import gkProgress from './components/gk-progress.vue';
import { ACTION_CONFIG_GET } from './store/const-actions';
import Meta from 'vue-meta';
import Vue from 'vue';
import VueMask from 'v-mask';

Vue.use(Meta);
Vue.use(VueMask);

export default {
  components: { gkProgress },
  name: 'app',
  metaInfo () {
    return {
      meta: [
        {
          name: 'viewport', content: 'width=device-width, initial-scale=1'
        },
        {
          name: 'theme-color', content: '#bcbbff'
        }
      ]
    };
  },

  data: () => ({
    configIsLoaded: false
  }),
  
  mounted () {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },

  created () {
    const vm = this;
    const getConfig = async () => {
      await vm.$store.dispatch(ACTION_CONFIG_GET, { tag: 'api' });
      vm.configIsLoaded = true;
    };
    getConfig();
  }
};
</script>

<style lang="scss">
  @import '../src/assets/style';

</style>

<style>

.theme--light.v-label {
  transition: none !important;
  transform: none !important;
}

div ::v-deep .v-label.theme--light {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  left: -10px !important;
  top: -28px !important;
  /*right: auto !important;*/
}

</style>
