import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/lib/services/goto';
import { ROUTE_CARD_HISTORY, ROUTE_CARD_INFO, ROUTE_CARD_SELECT, ROUTE_CARD_SUBSCRIPTIONS, ROUTE_CARD_TARIFICATIONS, ROUTE_HOME, ROUTE_PHONE, ROUTE_PROFILE } from './const-routes';

Vue.use(VueRouter);

function isAuthenticated () {
  const gkPhoneConfirmationToken = localStorage.getItem('gkPhoneConfirmationToken');
  if ((gkPhoneConfirmationToken !== undefined) && (gkPhoneConfirmationToken !== null))
    return true;
  return false;
}

const authOnly = (to, from, next) => {
  if (isAuthenticated()) {
    next();
    return;
  }
  next('/');
};

const redirectIfAuth = (to, from, next) => {
  if (isAuthenticated()) {
    next('/card-select');
    return;
  }
  next('/phone');
};

const routes = [
  { path: '/', beforeEnter: redirectIfAuth, name: ROUTE_HOME },
  { path: '/phone', name: ROUTE_PHONE, component: () => import('@/views/home.vue') },
  { path: '/card-select', beforeEnter: authOnly, name: ROUTE_CARD_SELECT, component: () => import ('@/views/card-select.vue') },
  { path: '/profile/:card', beforeEnter: authOnly, name: ROUTE_PROFILE, component: () => import ('@/views/profile.vue') },
  { path: '/card-info/:card', beforeEnter: authOnly, name: ROUTE_CARD_INFO, component: () => import ('@/views/card-info.vue') },
  { path: '/card-history/:card', beforeEnter: authOnly, name: ROUTE_CARD_HISTORY, component: () => import ('@/views/card-history.vue') },
  { path: '/card-tarifications/:card', beforeEnter: authOnly, name: ROUTE_CARD_TARIFICATIONS, component: () => import ('@/views/card-tarifications.vue') },
  { path: '/card-subscriptions/:card', beforeEnter: authOnly, name: ROUTE_CARD_SUBSCRIPTIONS, component: () => import ('@/views/card-subscriptions.vue') }
  // { path: '/dialogContentPage/:fileName', name: 'dialogContentPage', component: () => import ('@/components/dialogContentPage.vue') }
];

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    }
    else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
