import { http, throwIfError } from './tools';

export const apiVkAuth = {
  getVkAuthStartParams,
  vkAuthFinish
};

async function getVkAuthStartParams () {
  const response = await http.get('api/vkidauth/start');
  throwIfError(response);
  return response.data;
}

async function vkAuthFinish (payload) {
  const response = await http.post('api/vkidauth/finish', payload);
  throwIfError(response);
  return response.data;
}
