export const ACTION_LOAD_LOGO = 'ACTION_LOAD_LOGO';
export const ACTION_LOAD_DOC = 'ACTION_LOAD_DOC';

export const ACTION_AUTH_PHONE_EXISTS = 'ACTION_AUTH_PHONE_EXISTS';
export const ACTION_AUTH_SEND_CONFIRM_CODE_NO_PROFILE = 'ACTION_AUTH_SEND_CONFIRM_CODE_NO_PROFILE';
export const ACTION_AUTH_SEND_CONFIRM_CODE_PROFILE = 'ACTION_AUTH_SEND_CONFIRM_CODE_PROFILE';
export const ACTION_AUTH_RESEND_CONFIRM_CODE = 'ACTION_AUTH_RESEND_CONFIRM_CODE';
export const ACTION_AUTH_CONFIRM_PHONE = 'ACTION_AUTH_CONFIRM_PHONE';
export const ACTION_AUTH_LOGIN_BY_VKID = 'ACTION_AUTH_LOGIN_BY_VKID';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';

export const ACTION_AUTH_VK_AUTHORIZE = '  ACTION_AUTH_VK_AUTHORIZE';

export const ACTION_CARDS_CARDS_LIST = 'ACTION_CARDS_CARDS_LIST';
export const ACTION_CARDS_ISSUE_VC = 'ACTION_CARDS_ISSUE_VC';

export const ACTION_CARDS_HISTORY = 'ACTION_CARDS_HISTORY';
export const ACTION_CARDS_TARIFICATIONS = 'ACTION_CARDS_TARIFICATIONS';
export const ACTION_CARDS_SUBSCRIPTIONS = 'ACTION_CARDS_SUBSCRIPTIONS';

export const ACTION_PROFILE = 'ACTION_PROFILE';
export const ACTION_PROFILE_GET_OWNER = 'ACTION_PROFILE_GET_OWNER';
export const ACTION_PROFILE_GET_COOWNERS = 'ACTION_PROFILE_GET_COOWNERS';
export const ACTION_PROFILE_SAVE_OWNER = 'ACTION_PROFILE_SAVE_OWNER';
export const ACTION_PROFILE_SAVE_COOWNER = 'ACTION_PROFILE_SAVE_COOWNER';
export const ACTION_PROFILE_GET_PROFILE_CATEGORIES = 'ACTION_PROFILE_GET_PROFILE_CATEGORIES';

export const ACTION_CONFIG_GET = 'ACTION_CONFIG_GET';

export const ACTION_REDIRECT = 'ACTION_REDIRECT';
export const ACTION_REDIRECT_REFILL = 'ACTION_REDIRECT_REFILL';
export const ACTION_REDIRECT_BUY_TICKET = 'ACTION_REDIRECT_BUY_TICKET';
export const ACTION_REDIRECT_REPORT = 'ACTION_REDIRECT_REPORT';
