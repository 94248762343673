import Vue from 'vue';
import app from './app.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import '@fontsource/inter';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueYandexMetrika from 'vue-yandex-metrika';   

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify
});

// Тестоый счётчик
if (process.env.NODE_ENV !== 'production') {
  Vue.use(VueYandexMetrika, {
    id: 89201011,
    router: router,
    env: process.env.NODE_ENV// 'production'
    // other options
  });
}
// Боевой
else {
  Vue.use(VueYandexMetrika, {
    id: 89201607,
    router: router,
    env: process.env.NODE_ENV
    // other options
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(app)
}).$mount('#app');
