import { apiShared } from '@/api/api-shared';
import { ACTION_CONFIG_GET, ACTION_PROFILE_GET_PROFILE_CATEGORIES, ACTION_REDIRECT_REFILL, ACTION_REDIRECT_BUY_TICKET, ACTION_REDIRECT_REPORT, ACTION_REDIRECT, ACTION_LOAD_LOGO, ACTION_LOAD_DOC } from './const-actions';
import { MUTATION_CONFIG_LOGO_SET, MUTATION_CONFIG_SET, MUTATION_PROFILE_SET_CATEGORIES } from './const-mutations';
import { processActionAsync } from './process-action';
import { getQueryString } from '@/api/tools.js';

const state = {
  refillPageUrl: null,
  byTicketPageUrl: null,
  byTicketPosId: null,
  reportPageUrl: null,
  tgLinkFb: null,
  tgLinkBot: 'https://t.me/ParkGK_Bot',
  viberLinkFb: null,
  vkLinkFb: null,
  whatsappLinkFb: null,
  catalogT2P: false,
  needChildrenInProfile: false,
  enableIssueCardButton: false,
  disableInBonusProgramCheckbox: false,
  issueCardBySms: false,
  locale: 'ru',
  excludedKinds1: '2, 3',
  excludedKinds2: '1, 3',
  excludedKinds3: '2, 1',
  footer: {
    name: null,
    address: null,
    phone: null,
    email: null,
    workingHours: null
  },
  requiredOwnerFields: {
    firstName: false,
    lastName: false,
    middleName: false,
    birthday: false,
    gender: false,
    email: false,
    category1: false,
    category2: false,
    category3: false
  },
  displayProfileLastName: true,
  displayProfileFirstName: true,
  displayProfileMiddleName: true,
  displayProfileBirthday: true,
  displayProfileGender: true,
  displayProfileEmail: true,
  displayProfileCategory1: true,
  displayProfileCategory2: false,
  displayProfileCategory3: false,
  labelProfileCategory1: '',
  labelProfileCategory2: '',
  labelProfileCategory3: '',

  colorElement: '',
  cardColorSecondary: 'FFFFFF',
  colorButton: '',
  colorText: '',
  colorTextSecondary: '#000000',

  tabbarColorButton: '',
  tabbarColorButtonBackground: '',
  tabbarColorText: '',
  tabbarColorTextActive: '',

  buttonAuth: false,
  buttonMailRu: false,
  buttonOkRu: false,
  buttonShowAlternativeLogin: false,
  
  appVersion: '?',
  api2CardsVersion: '?',
  logo: null
};

const actions = {
  async [ACTION_CONFIG_GET] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiShared.config();
      context.commit(MUTATION_CONFIG_SET, result.data);
      return result.data;
    });
  },

  async [ACTION_REDIRECT_REFILL] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      await new Promise(() => {
        const backUrl = encodeURIComponent(window.location.href);
        let ref = `${context.state.refillPageUrl}/?card=${payload.card}&backurl=${backUrl}`;

        // Добавляем sum в URL, если он не равен undefined или null
        if (payload.sum !== undefined && payload.sum !== null) {
          ref += `&sum=${payload.sum}`;
        }

        window.location = ref;
      });
    });
  },

  async [ACTION_REDIRECT_BUY_TICKET] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      await new Promise(() => {
        // const phone = encodeURIComponent(localStorage.getItem('gkPhoneConfirmationToken').split(' ')[0]);
        const posId = encodeURIComponent(context.state.byTicketPosId);
        const cardNum = encodeURIComponent(payload.card);
        let sumParam = '';
        if (payload.sum !== undefined && payload.sum !== null) {
          sumParam = `&sum=${payload.sum}`;
        }
        const backurl = encodeURIComponent(window.location.href);

        const queryString = getQueryString({ posId, cardNum, sum: payload.sum, backurl });
        const ref = `${context.state.byTicketPageUrl}${queryString}${sumParam}`;

        window.location = ref;
      });
    });
  },

  async [ACTION_REDIRECT_REPORT] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      await new Promise(() => {
        const backUrl = encodeURIComponent(window.location.href);
        const ref = `${context.state.reportPageUrl}/?card=${payload.card}&backurl=${backUrl}`;
        window.location = ref;
      });
    });
  },

  async [ACTION_REDIRECT] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const ref = payload.link;
      window.location = ref;
    });
  },

  async [ACTION_LOAD_LOGO] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiShared.getLogo();
      context.commit(MUTATION_CONFIG_LOGO_SET, result);
      return result;
    });
  },

  async [ACTION_LOAD_DOC] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiShared.getDoc(payload.documentName);
      return result;
    });
  },

  async [ACTION_PROFILE_GET_PROFILE_CATEGORIES] (context, kind) {
    return await processActionAsync(context, {}, async function () {
      const result = await apiShared.profileCategories(kind);
      context.commit(MUTATION_PROFILE_SET_CATEGORIES, result.data);
      return result.data;
    });
  }
};

const mutations = {

  [MUTATION_CONFIG_SET] (state, payload) {
    Object.assign(state, payload);
  },
  [MUTATION_CONFIG_LOGO_SET] (state, payload) {
    state.logo = payload;
  },
  [MUTATION_PROFILE_SET_CATEGORIES] (state, categories) {
    state.categories = categories;
  }
};

export default {
  state,
  actions,
  mutations
};
