import { getQueryString, http, throwIfError } from './tools';

export const apiAuth = {
  phoneExists,
  sendConfirmCode,
  reSendConfirmCode,
  confirmPhone,

  getCardToPhone,
  getOwner,
  getCoOwners,
  saveOwner,
  saveCoowner,
  getPdf,
  getPdfBase64
};

async function phoneExists (phone) {
  const queryString = getQueryString({ phone });
  const response = await http.get(`api/auth/phoneExists${queryString}`);
  throwIfError(response);
  return response.data;
}

async function sendConfirmCode (phone, profile) {
  const queryString = getQueryString({ phone });
  const response = await http.post(`api/auth/sendConfirmCode${queryString}`, profile);
  throwIfError(response);
  return response.data;
}

async function reSendConfirmCode (phone) {
  const queryString = getQueryString({ phone });
  const response = await http.post(`api/auth/reSendConfirmCode${queryString}`);
  throwIfError(response);
  return response.data;
}

async function confirmPhone (phone, code) {
  const response = await http.post('api/auth/confirmPhone', { phone, code });
  throwIfError(response);
  return response.data;
}

async function getCardToPhone (token, card) {
  const response = await http.post('api/getCardToPhone', { token, card });
  throwIfError(response);
  return response.data;
}

async function getOwner (token, card) {
  const queryString = getQueryString({ token, card });
  const response = await http.get(`api/owner${queryString}`);
  throwIfError(response);
  return response.data;
}

async function getCoOwners (token, card) {
  const queryString = getQueryString({ token, card });
  const response = await http.get(`api/coOwners${queryString}`);
  throwIfError(response);
  return response.data;
}

async function saveOwner (token, owner) {
  const response = await http.post('api/owner', { token: token, ...owner });
  throwIfError(response);
  return response.data;
}

async function saveCoowner (token, coowner) {
  const response = await http.post('api/coOwner', { token: token, ...coowner });
  throwIfError(response);
  return response.data;
}

async function getPdf (token, card) {
  const response = await http.get('api/getPdf', { responseType: 'arraybuffer' });
  throwIfError(response);
  return response;
}

async function getPdfBase64 (token, card) {
  const response = await http.get('api/getPdfBase64');
  throwIfError(response);
  return response.data;
}
