import { ERROR_CLASS_TRANSPORT, ERROR_CLASS_FROM_API } from './const-errors';

export default class {
  constructor () {
    this.errorObj = {
      errorClass: ERROR_CLASS_TRANSPORT,
      errors: [],
      payload: null
    };
  }

  handleTransportError = function (error) {
    // Проверка наличия интернет-соединения
    if (!navigator.onLine) {
      this.errorObj.errors = [{ code: 'No Internet Connection', message: 'Отсутствует подключение к интернету' }];
      throw this.errorObj;
    }
    this.errorObj.errors = [{ code: 'Api access error', message: 'Ошибка доступа к API' }];
    if (!error)
      throw this.errorObj;
    if (!error.response)
      throw this.errorObj;
    if (!error.response.status)
      throw this.errorObj;

    this.errorObj.status = error.response.status;

    if (this.errorObj.status === 401)
      this.errorObj.errors[0].message = 'Для входа в личный кабинет вам необходимо авторизоваться';
    if (this.errorObj.status === 403)
      this.errorObj.errors[0].message = 'Доступ запрещён';
    if (this.errorObj.status === 404)
      this.errorObj.errors[0].message = 'Адрес не найден';
    this.errorObj.errors[0].statusText = error.response.statusText;
    throw this.errorObj;
  };

  processResponseError = function (response, throwIfError = false) {
    this.errorObj.errorClass = ERROR_CLASS_FROM_API;
    if (!navigator.onLine) {
      this.errorObj.errors = [{ code: 'No Internet Connection', message: 'Отсутствует подключение к интернету' }];
      throw this.errorObj;
    }

    if (response.data !== undefined) {
      if (response.data.errors && response.data.errors.length > 0) {
        // Проверка на ошибку "Номер телефона не найден"
        if (response.data.errors.some(err => err.message === 'Номер телефона не найден. The phone number is not found.')) {
          this.errorObj.errors = [{ code: 'PhoneNotFound', message: 'Номер телефона не найден. Обратитесь к администратору.' }];
          throw this.errorObj;
        }
        this.errorObj.errors = response.data.errors;
        throw this.errorObj;
      }
      return response.data;
    }

    this.errorObj.errors = [{ code: 'Api response error', message: 'Неверный ответ от API' }];
    this.errorObj.payload = response;
    if (throwIfError) 
      throw this.errorObj;

    return response;
  };

  handleVkAuthError = function (error) {
    this.errorObj.errorClass = ERROR_CLASS_FROM_API;

    if (!navigator.onLine) {
      this.errorObj.errors = [{ code: 'No Internet Connection', message: 'Отсутствует подключение к интернету' }];
      throw this.errorObj;
    }

    this.errorObj.errors = [{ code: 'VK Auth Error', message: 'Произошла ошибка при авторизации через VK' }];
    
    // Специфичные ошибки VK
    if (error.code === 'auth_error') {
      this.errorObj.errors[0].message = 'Ошибка авторизации. Пожалуйста, попробуйте снова.';
    } 
    else if (error.code === 'network_error') {
      this.errorObj.errors[0].message = 'Проблема с сетью. Проверьте подключение к интернету.';
    } 
    else if (error.code === 0 && error.text === 'timeout') {
      this.errorObj.errors[0].message = 'Авторизация через VK была отменена из-за таймаута. Пожалуйста, попробуйте снова.';
    } 
    else {
      this.errorObj.errors[0].message = 'Произошла неизвестная ошибка. Попробуйте еще раз.';
    }

    this.errorObj.payload = error;
    throw this.errorObj;
  };
}
