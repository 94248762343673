import moment, { now } from 'moment';

export function formatStringDateToYyyyMmDd (stringValue) {
  if (stringValue === null)
    return null;
  return moment(new Date(stringValue)).format('YYYY-MM-DD');
}

export function formatStringDateToInput (dateFromAPI) {
  if ((dateFromAPI === null) || (dateFromAPI === undefined))
    return null;
  const localDate = new Date(dateFromAPI.split('T')[0]);
  const day = localDate.getDate();
  const month = localDate.getMonth() + 1;
  const year = localDate.getFullYear();
  const dateWithDots = day.toString().padStart(2, '0') + '.' + month.toString().padStart(2, '0') + '.' + year.toString().padStart(4, '0');
  return dateWithDots;
}

export function formatStringDateFromInput (stringValue) {
  if ((stringValue === null) || (stringValue === undefined) || (stringValue === ''))
    return null;
  const day = stringValue.substring(0, 2).toString().padStart(2, '0');
  const month = stringValue.substring(3, 5);
  const year = stringValue.substring(6, 10);
  const result = year + '-' + month + '-' + day;
  return result;
}

export function formatStringDateToHHMM (stringValue) {
  if (stringValue === null)
    return null;
  return moment(new Date(stringValue)).format('HH:MM');
}

export function formatStringDateToLocal (stringValue) {
  if (stringValue === null)
    return null;
  const asDate = new Date(stringValue);
  return asDate.toLocaleDateString();
}

export function formatStringDateTimeToLocal (stringDateTime) {
  if (stringDateTime === null)
    return null;
  const asDate = new Date(stringDateTime);
  return `${asDate.toLocaleDateString()}, ${('0' + asDate.getHours()).slice(-2) + ':' + ('0' + asDate.getMinutes()).slice(-2)}`;
}

export function formatStringDateToReadableWithYear (date) {
  if (date === null) {
    return null;
  }
  return new Date(date).toLocaleString('ru', {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'short'
  });
}

export function formatTimeToHoursMinutes (stringTime) {
  if (stringTime === null)
    return null;
  // С оставшимся временем
  if (stringTime < 0) {
    stringTime = -stringTime;
    const days = (stringTime / 60) / 24;
    const rdays = Math.floor(days);
    const hours = (days - rdays) * 24;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.floor(minutes);

    if (rdays !== 0) {
      if (rhours !== 0) {
        if (rminutes !== 0) {
          return rdays + ' дн ' + rhours + ' ч ' + rminutes + ' мин';
        }
        else {
          return rdays + ' дн ' + rhours + ' ч ';
        }
      }
      else {
        if (rminutes !== 0) {
          return rdays + ' дн ' + rminutes + ' мин';
        }
        else {
          return rdays + ' дн';
        }
      }
    }
    else {
      if (rhours !== 0) {
        if (rminutes !== 0) {
          return rhours + ' ч ' + rminutes + ' мин';
        }
        else {
          return rhours + ' ч ';
        }
      }
      else {
        if (rminutes !== 0) {
          if ((rminutes <= 1) && (rminutes > 0)) {
            return 'меньше 1 мин';
          }
          return rminutes + ' мин ';
        }
      }
    }
  }
  // С превышенным временем
  const days = (stringTime / 60) / 24;
  const rdays = Math.floor(days);
  const hours = (days - rdays) * 24;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rdays !== 0) {
    if (rhours !== 0) {
      if (rminutes !== 0) {
        return rdays + ' дн ' + rhours + ' ч ' + rminutes + ' мин';
      }
      else {
        return rdays + ' дн ' + rhours + ' ч ';
      }
    }
    else {
      if (rminutes !== 0) {
        return rdays + ' дн ' + rminutes + ' мин';
      }
      else {
        return rdays + ' дн';
      }
    }
  }
  else {
    if (rhours !== 0) {
      if (rminutes !== 0) {
        return rhours + ' ч ' + rminutes + ' мин';
      }
      else {
        return rhours + ' ч ';
      }
    }
    else {
      if (rminutes !== 0) {
        if ((rminutes <= 1) && (rminutes > 0)) {
          return 'меньше 1 мин';
        }
        return rminutes + ' мин ';
      }
    }
  }
}

export function formatStringTimeToLocal (stringDateTime) {
  if (stringDateTime === null)
    return null;
  const asDate = new Date(stringDateTime);
  return `${('0' + asDate.getHours()).slice(-2) + ':' + ('0' + asDate.getMinutes()).slice(-2)}`;
}

export function formatCurrency (value) {
  return new Intl.NumberFormat('ru-RU', { useGrouping: true }).format(value);
}

export function formatCard (value) {
  return new Intl.NumberFormat('ru-RU', { useGrouping: true }).format(value);
}

export function daysToExpire (date) {
  const today = new Date(now());
  const dateStop = new Date(date);
  var timeDiff = Math.abs(dateStop.getTime() - today.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
}

export function formatStringDateToReadable (date) {
  if (date === null)
    return null;
  return new Date(date).toLocaleString('ru', {
    month: 'long',
    day: 'numeric'
  });
}

export function getNoun (number) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return ' дней';
  }
  n %= 10;
  if (n === 1) {
    return ' день';
  }
  if (n >= 2 && n <= 4) {
    return ' дня';
  }
  return ' дней';
}

export function getMinutesNounForm (minutes) {
  let n = Math.abs(minutes);
  minutes %= 100;
  if (n >= 5 && n <= 20) {
    return ' минут';
  }
  n %= 10;
  if (n === 1) {
    return ' минута';
  }
  if (n >= 2 && n <= 4) {
    return ' минуты';
  }
  return ' минут';
}

export function getHoursNounForm (hours) {
  let n = Math.abs(hours);
  hours %= 100;
  if (n >= 5 && n <= 20) {
    return ' часов ';
  }
  n %= 10;
  if (n === 1) {
    return ' час ';
  }
  if (n >= 2 && n <= 4) {
    return ' часа ';
  }
  return ' часов ';
}

export function calculateTimeleft (date) {
  if (date === null) {
    return null;
  }
  const today = new Date(now());
  const dateStop = new Date(date);
  const milliseconds = dateStop.getTime() - today.getTime();
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
  const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);
  const days = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

  if (seconds <= -1) {
    return null;
  }
  else if (days >= 7) {
    return dateStop;
  }
  else if (days < 7) {
    return (days !== 0) ? days + ' дн ' + hours + ' ч ' + minutes + ' мин ' : hours + ' ч ' + minutes + ' мин ';
  }
}

export function classToTarification (delta) {
  if (delta < 0) {
    return 'red';
  }
  else {
    return 'green';
  }
}

// Добавляет к дате годы. В JS если добавить к 2012-02-29 1 год, будет 2013-03-01 а в C# 2013-02-28 этот случай обрабатываю отдельно для общности с C#
export function addYears (date, years) {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + years);
  if ((date.getDate() === 29) && (date.getMonth() === 1) && (dateCopy.getMonth() === 2) && (dateCopy.getDate() === 1)) {
    dateCopy.setDate(dateCopy.getDate() - 1);
  }
  return dateCopy;
}

