import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/module-auth';
import cards from '@/store/module-cards';
import profile from '@/store/module-profile';
import progress from '@/store/module-progress';
import shared from '@/store/module-shared.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    onButtonClickFlag: false,
    currentIndex: 0,
    currentCard: null // Добавляем currentCard в state
  },
  mutations: {
    setOnButtonClickFlag (state, value) {
      state.onButtonClickFlag = value;
    },
    SET_0N_BUTTON_CLICK_FLAG (state, flag) {
      state.onButtonClickFlag = flag;
    },
    SET_CURRENT_CARD (state, card) { // Мутация для обновления текущей карты
      state.currentCard = card;
    }
  },
  actions: {
    updateCurrentCard ({ commit }, card) { // Действие для обновления карты
      commit('SET_CURRENT_CARD', card);
      localStorage.setItem('currentCard', card); // Сохраняем карту в localStorage
    },
    loadCurrentCard ({ commit }) { // Действие для загрузки карты из localStorage
      const savedCard = localStorage.getItem('currentCard');
      if (savedCard) {
        commit('SET_CURRENT_CARD', savedCard);
      }
    }
  },
  watch: {
    $route (to) {
      const card = to.params.card;
      if (card) {
        localStorage.setItem('currentCard', card); // Сохраняем номер карты при изменении маршрута
      }
    }
  },
  modules: {
    auth,
    cards,
    profile,
    progress,
    shared
  }
});
