export const MUTATION_PROGRESS_INIT = 'MUTATION_PROGRESS_INIT';
export const MUTATION_PROGRESS_NONE = 'MUTATION_PROGRESS_NONE';
export const MUTATION_PROGRESS_ERROR = 'MUTATION_PROGRESS_ERROR';
export const MUTATION_PROGRESS_OK = 'MUTATION_PROGRESS_OK';
export const MUTATION_PROGRESS_IN_PROGRESS = 'MUTATION_PROGRESS_IN_PROGRESS';
export const MUTATION_SET_ERROR = 'MUTATION_SET_ERROR';

export const MUTATION_AUTH_INIT = 'MUTATION_AUTH_INIT';
export const MUTATION_AUTH_SET = 'MUTATION_AUTH_SET';
export const MUTATION_AUTH_SET_PHONE_CONFIRMATION_TOKEN = 'MUTATION_AUTH_SET_PHONE_CONFIRMATION_TOKEN';
export const MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN = 'MUTATION_AUTH_REMOVE_PHONE_CONFIRMATION_TOKEN';

export const MUTATION_CARDS_SET_INIT = 'MUTATION_CARDS_SET_INIT';
export const MUTATION_CARDS_SET_CARDS_LIST = 'MUTATION_CARDS_SET_CARDS_LIST';

export const MUTATION_CARDS_SET_CARD_HISTORY = 'MUTATION_CARDS_SET_CARD_HISTORY';
export const MUTATION_CARDS_SET_CARD_TARIFICATIONS = 'MUTATION_CARDS_SET_CARD_TARIFICATIONS';
export const MUTATION_CARDS_SET_CARD_SUBSCRIPTIONS = 'MUTATION_CARDS_SET_CARD_SUBSCRIPTIONS';

export const MUTATION_PROFILE_INIT = 'MUTATION_PROFILE_INIT';
export const MUTATION_PROFILE_SET_OWNER = 'MUTATION_PROFILE_SET_OWNER';
export const MUTATION_PROFILE_SET_COOWNERS = 'MUTATION_PROFILE_SET_COOWNERS';
export const MUTATION_PROFILE_ADD_COOWNER = 'MUTATION_PROFILE_ADD_COOWNER';
export const MUTATION_PROFILE_UPDATE_COOWNER = 'MUTATION_PROFILE_UPDATE_COOWNER';
export const MUTATION_PROFILE_DELETE_COOWNER = 'MUTATION_PROFILE_DELETE_COOWNER';
export const MUTATION_PROFILE_EDIT_OWNER = 'MUTATION_PROFILE_EDIT_OWNER';
export const MUTATION_PROFILE_SET_CATEGORIES = 'MUTATION_PROFILE_SET_CATEGORIES';

export const MUTATION_CONFIG_SET = 'MUTATION_CONFIG_SET';
export const MUTATION_CONFIG_LOGO_SET = 'MUTATION_CONFIG_LOGO_SET';
