import { processActionAsync } from '@/store/process-action';
import { 
  ACTION_CARDS_CARDS_LIST,
  ACTION_CARDS_HISTORY,
  ACTION_CARDS_ISSUE_VC,
  ACTION_CARDS_SUBSCRIPTIONS,
  ACTION_CARDS_TARIFICATIONS
} from '@/store/const-actions';
import {
  MUTATION_CARDS_SET_CARDS_LIST,
  MUTATION_CARDS_SET_INIT, MUTATION_CARDS_SET_CARD_HISTORY, MUTATION_CARDS_SET_CARD_TARIFICATIONS, MUTATION_CARDS_SET_CARD_SUBSCRIPTIONS
} from './const-mutations';
import { apiCards } from '@/api/api-cards';

function initState () {
  return {
    cardsList: [],
    cardHistory: [],
    cardTarifications: [],
    cardSubscriptions: []
  };
}

const state = initState();

const actions = {

  async [ACTION_CARDS_CARDS_LIST] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      context.commit(MUTATION_CARDS_SET_CARDS_LIST, []);
      const result = await apiCards.cardsList();
      context.commit(MUTATION_CARDS_SET_CARDS_LIST, result.data);
      return result.data;
    });
  },
  
  async [ACTION_CARDS_ISSUE_VC] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.issueVc(payload.card);
      return result.data;
    });
  },

  async [ACTION_CARDS_HISTORY] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.history(payload.card);
      // ToDo - тут имеем баг, если загрузка быстрая то остаётся серое перекрытие
      // const result = await new Promise((resolve, reject) => {
      //   const r = TestData();
      //   resolve(r);
      // });
      context.commit(MUTATION_CARDS_SET_CARD_HISTORY, result.data);
      return result.data;
    });
  },

  async [ACTION_CARDS_TARIFICATIONS] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.tarifications(payload.card);
      context.commit(MUTATION_CARDS_SET_CARD_TARIFICATIONS, result.data);
      return result.data;
    });
  },

  async [ACTION_CARDS_SUBSCRIPTIONS] (context, payload) {
    return await processActionAsync(context, payload, async function () {
      const result = await apiCards.subscriptions(payload.card);
      context.commit(MUTATION_CARDS_SET_CARD_SUBSCRIPTIONS, result.data);
      return result.data;
    });
  }

};

const mutations = {

  [MUTATION_CARDS_SET_INIT] (state) {
    state = Object.assign(state, initState());
  },

  [MUTATION_CARDS_SET_CARDS_LIST] (state, cardsList) {
    state.cardsList = cardsList;
  },

  [MUTATION_CARDS_SET_CARD_HISTORY] (state, cardHistory) {
    state.cardHistory = cardHistory;
  },

  [MUTATION_CARDS_SET_CARD_TARIFICATIONS] (state, cardTarifications) {
    state.cardTarifications = cardTarifications;
  },

  [MUTATION_CARDS_SET_CARD_SUBSCRIPTIONS] (state, cardSubscriptions) {
    state.cardSubscriptions = cardSubscriptions;
  }
};

export default {
  state,
  actions,
  mutations
};
